<template>
    <div>
        <p>{{ this.message }}</p>
        <b-button @click="$router.push('/login')" variant="primary">Log In</b-button>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Axios from "axios";

export default {
    data: function() {
    return {
      message: "Something went wrong."
      }
  },
    mounted() { this.logout() },

    computed: {
        ...mapGetters({ token: "token", email: "email" })
    },

    methods: {
        ...mapMutations({ setToken: "setToken", setEmail: "setEmail"}),
        async logout() {           
            let self = this;
            Axios.delete("/auth", {
                params: {
                    token: this.token,
                },
                headers: {"Content-Type": "application/json"}
            }).then(function(response) {
                const status = response.status;
                if (status == '200' || status == '204') {
                    self.message = "You have been logged off.";
                    self.setToken(null);
                    self.setEmail(null);
                }
            })
            .catch(error => {
                console.log(error)
                this.errorMsg = "ERROR";
            });
        }
    }
}
</script>